@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

.Home {
  background-color: #160d08;
  min-height: 110vh;
  text-align: center;
}

.Home-header {
  display: flex;
  flex-direction: column;
  font-family: 'League Gothic', sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 8rem;
  color: white;
  margin-top: -20px;
}

.arrow-bottom {
  position: absolute;
  bottom: 10px;
  left: 45%;
  transform: translateX(-50%);
  font-size: 3rem;
}

.arrow-left {
  left: -70px;
  font-size: 3rem;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}

.arrow-left-bottom-corner {
  left: -40px;
  font-size: 3rem;
  position: absolute;
  transform: translateY(-50%) rotate(20deg);
  bottom: -10%;
}

.arrow-left-top-corner {
  left: -70px;
  font-size: 3rem;
  position: absolute;
  top: -10%;
  transform: translateY(-50%);
}

.arrow-right {
  font-size: 3rem;
  position: absolute;
  right: -80px;
  top: 30%;
  transform: translateY(-50%);
}

.arrow-right-bottom-corner {
  right: -40px;
  font-size: 3rem;
  position: absolute;
  transform: translateY(-50%) rotate(60deg);
  bottom: -10%;
}

.arrow-right-top-corner {
  right: -40px;
  font-size: 3rem;
  position: absolute;
  transform: translateY(-50%) rotate(-50deg);
  top: -10%;
}

.arrows {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.arrow-top {
  left: 45%;
  position: absolute;
  top: -50px;
  transform: translateX(-50%);
  font-size: 3rem;
}

audio {
  display: none;
}

button {
  background-color: #008080;
  border: none;
  color: white;
  cursor: pointer;
  font-family: 'League Gothic', sans-serif;
  font-size: 2rem;
  margin: 10px;
  margin-bottom: 80px;
  padding: 20px 40px;
}

.button-container {
  display: flex;
  gap: 80px;
  justify-content: center;
  padding-bottom: 30px;
}

.button-container-arrows-visible {
  gap: 150px;
  padding-top: 80px;
}

button:hover {
  background-color: #005f5f;
}

.caution-tape {
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/caution-tape.png') no-repeat center center;
  background-size: cover;
}

i {
  color: white;
  margin: 0 10px;
}

.instructions {
  font-size: 4rem;
  margin-top: -20px;
}

.left-button-fade {
  animation: fadeOut 1s forwards;
}

.next-chapter {
  font-size: 4rem;
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 3s;
}

.next-chapter-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.next-chapter-buttons button {
  background-color: #008080;
  border: none;
  color: white;
  cursor: pointer;
  font-family: 'League Gothic', sans-serif;
  font-size: 2rem;
  padding: 10px 20px;
}

.next-chapter-buttons button:hover {
  background-color: #005f5f;
}

.no-button.fade-out {
  animation: fadeOut 1s forwards;
}

p {
  font-size: 4rem;
  margin-top: -30px;
}

.right-button-large {
  font-size: 3rem;
}

.right-button-wrapper {
  display: inline-block;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
