.NextChapter {
  background-color: #160d08;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luca {
  max-width: 50vw;
  max-height: 50vh;
  height: auto;
  width: auto;
}
